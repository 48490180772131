/* Box sizing and reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0.25rem;
}

/* Body styles */
body {
  font-family: Arial, sans-serif;
  background-color: #FFFFFF;
}

/* Links styles */
a {
  color: #000;
  text-decoration: none;
}

img {
  max-width: 95%;
  max-height: 95%;
  display: block;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;float: left;
}

/* Gradient background */
.gradient__bg {
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

/* Gradient text */
.gradient__text {
  background: linear-gradient(to right, #6A58B6, #8670BF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Section styles */
.section {
  padding: 6rem;
  margin-bottom: 3rem;
}

/* Section header */
.section__header {
  text-align: center;
  margin-bottom: 2rem;
}

/* Scale up center animation */
.scale-up-center {
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive styles */
@media screen and (max-width: 700px) {
  .section {
    padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section {
    padding: 2rem;
  }
}

.photo{
  height: 700px;
  width: 75%;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  font-weight: bold;
}

.form-group input[type="checkbox"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #fff;
  resize: none;
}

.form-group textarea {
  height: 10rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.col-md-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
  padding-right: 5px;
  padding-left: 5px;
}

.col-md-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
  padding-right: 5px;
  padding-left: 5px;
}

.col-md-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
  padding-right: 5px;
  padding-left: 5px;
}

.btn-primary {
  background-color: #000000;
  border-color: #FFFFFF;
  color: #FFFFFF;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: #fff;
}

.toolbar {
  font-size: 22px;
}